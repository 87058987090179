import React from 'react'
import { CgSpinner } from 'react-icons/cg'

export default function Loading({ className, size = '40' }) {
  return (
    <div className={`block rounded-full leading-none ${className ?? ''} mx-auto`}>
      <CgSpinner className={`animate-spin w-[${size}px] h-[${size}px]`} />
    </div>
  )
}
