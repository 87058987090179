import axios from 'axios'
import _ from 'lodash'
import { WALKMINING_EXPLORER_API_ENDPOINT } from '../../config'

const headers = { 'Content-Type': 'application/json' }
const ACCESS_TOKEN_KEY = 'Authorization'

export const responseOk = (res) => {
  return {
    status: res.status,
    data: _.isArray(res.data) ? [...res.data] : res.data,
  }
}

export const responseError = (error) => {
  let status = 503
  let message = 'Error'

  if (!_.isEmpty(error.response)) {
    status = error.response.status ? error.response.status : 500
    message = error.response.data.errorMessage || error.message || ''
  }
  return {
    status,
    message,
  }
}

/* eslint-disable no-undef */
const authHttp = (isSSR, { accessToken }) =>
  axios.create({
    baseURL: isSSR ? WALKMINING_EXPLORER_API_ENDPOINT : '/api/v1/',
    timeout: 10000,
    headers: {
      ...headers,
      ...(accessToken ? { [ACCESS_TOKEN_KEY]: accessToken } : {}),
    },
  })

/* eslint-disable no-undef */
const http = (isSSR) =>
  axios.create({
    baseURL: isSSR ? WALKMINING_EXPLORER_API_ENDPOINT : '/api/v1/',
    timeout: 10000,
    headers: {
      ...headers,
    },
  })

/* eslint-disable no-undef */
const longHttp = (isSSR) =>
  axios.create({
    baseURL: isSSR ? WALKMINING_EXPLORER_API_ENDPOINT : '/api/v1/',
    timeout: 60000,
    headers: {
      ...headers,
    },
  })

const longProxyUploadHttp = () =>
  axios.create({
    baseURL: '/',
    timeout: 60000,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export default http
export { http, longHttp, authHttp, longProxyUploadHttp }
