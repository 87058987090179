import moment from 'moment'

export function getHourDate() {
  return new Date().getTime() + 60 * 60 * 1000
}

export function calCurrencyPrice(marketPrice, currencyPrice) {
  if (currencyPrice > 0) {
    return marketPrice * currencyPrice
  }
  return marketPrice
}

export function getSearchResultLinkPath(type, value) {
  var basePath = '/'
  if (type === 'USER') {
    basePath = '/user/'
  } else if (type === 'NFT') {
    basePath = '/nft/'
  }

  return basePath + value
}

export function numberWithCommasSteps(n, subfix = 'Steps') {
  try {
    if (!n || n === 0) {
      return `0 ${subfix}`
    }
    const parts = n.toString().split('.')
    return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '')} ${subfix}`
  } catch (e) {
    return `${n} ${subfix}`
  }
}

export function numberWithCommasNormal(n) {
  try {
    if (!n || n === 0) {
      return 0
    }
    const parts = n.toString().split('.')
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '')
  } catch (e) {
    return n
  }
}

export function numberWithCommas(x, currency) {
  if (!x || x === 0) {
    return 0
  }

  if (currency.standardType === 'KRW') {
    return `${Number(x)
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  }
  return x
}

export function isIncrement(num, step) {
  return Math.abs((num / step) % 1) < 1e-5 || Math.abs(((num / step) % 1) - 1) < 1e-5
}

export function displayedAt(createdAt) {
  const milliSeconds = new Date().getTime() - createdAt
  const seconds = milliSeconds / 1000
  if (seconds < 60) return `a few seconds ago`
  const minutes = seconds / 60
  if (minutes < 60) return `${Math.floor(minutes)}minute ago`
  const hours = minutes / 60
  if (hours < 24) return `${Math.floor(hours)}hours ago`
  const days = hours / 24
  if (days < 7) return `${Math.floor(days)}days ago`
  return new Date(createdAt).toLocaleString()
}

export const textSlice = (text, lengths) => {
  if (text.length > lengths) {
    return text.substr(0, lengths - 2) + '...'
  } else {
    return text
  }
}

export function handleImageError(e) {
  return (e.target.src = noSymbolIcon)
}

export function percentColor(percent) {
  return percent < 0 ? 'text-[#E35461]' : percent === 0 ? 'text-gray-400' : 'text-[#5EBA89]'
}

export function percentString(percent) {
  return `${percent > 0 ? '+' : ''}${percent.toFixed(2)}%`
}

export function getDiffDates(from, to, format = 'YYYY-MM-DD') {
  const diffDays = to.diff(from, 'days')
  var dates = []
  for (var i = 0; i < diffDays; i++) {
    const fromDay = moment(from)
    dates.push(fromDay.add(i, 'days').format(format))
  }
  return dates
}

export function getRewardShopSupportTokenIconUrl(symbol) {
  const currencyData = [
    {
      image: 'https://ad-banner.walkmining.com/web/ic_coin_wkm.png',
      symbol: 'WKM',
    },
    {
      image: 'https://ad-banner.walkmining.com/web/ic_coin_wkg.png',
      symbol: 'WKG',
    },
    {
      image: 'https://ad-banner.walkmining.com/web/ic_xpla.png',
      symbol: 'XPLA',
    },
    {
      image: 'https://ad-banner.walkmining.com/web/ic_solana.png',
      symbol: 'SOL',
    },
    {
      image: 'https://ad-banner.walkmining.com/web/ic_bsc.png',
      symbol: 'BSC',
    },
  ]
  return currencyData.find((f) => f.symbol === symbol)?.image || ''
}

export function getShopItemSalePricePercent(basePrice, salePrice) {
  const percent = ((Number(salePrice) / Number(basePrice)) * 100).toFixed(0)
  return -(Number(percent) - 100)
}

export function parseShopDetailString(text) {
  if (isHtmlCode(text)) {
    return text
  } else {
    const urls = collectURL(text)
    var resultText = text.replace('\n', '<br/>')
    if (urls && urls.length > 0) {
      urls.map((url) => {
        resultText = resultText.replace(url, `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`)
      })
    }
    return resultText
  }
}

export function collectURL(text) {
  if (text && text.trim().length > 0) {
    const rUrlRegex =
      /(?:(?:(https?|ftp|telnet):\/\/|[\s\t\r\n\[\]\`\<\>\"\'])((?:[\w$\-_\.+!*\'\(\),]|%[0-9a-f][0-9a-f])*\:(?:[\w$\-_\.+!*\'\(\),;\?&=]|%[0-9a-f][0-9a-f])+\@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9\-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?:\:([0-9]+))?((?:\/(?:[\w$\-_\.+!*\'\(\),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s\/\?\.:<>|#]*(?:\.[^\s\/\?:<>|#]+)*))?(\/?[\?;](?:[a-z0-9\-]+(?:=[^\s:&<>]*)?\&)*[a-z0-9\-]+(?:=[^\s:&<>]*)?)?(#[\w\-]+)?)/gim
    return text.match(rUrlRegex)
  }
  return undefined
}

export function isHtmlCode(text) {
  const htmlTagRe = /<[a-z][\s\S]*>/i
  if (text.match(htmlTagRe)) {
    return true
  }
  return false
}
