import React from 'react'
import { toast } from 'react-toastify'

export function notify({
  message,
  description,
  type = 'info',
  placement = 'bottom-left',
  time = 3000,
  hideProgressBar = false,
  theme = 'dark',
}) {
  const toastMessage = (
    <>
      {message}
      {description && <div>{description}</div>}
    </>
  )
  if (type === 'error') {
    toast.error(toastMessage, {
      position: placement,
      autoClose: time,
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      theme: theme,
      draggable: true,
    })
  } else if (type === 'warn') {
    toast.warn(toastMessage, {
      position: placement,
      autoClose: time,
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      theme: theme,
      draggable: true,
    })
  } else if (type === 'success') {
    toast.success(toastMessage, {
      position: placement,
      autoClose: time,
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      theme: theme,
      draggable: true,
    })
  } else {
    toast.info(toastMessage, {
      position: placement,
      autoClose: time,
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      theme: theme,
      draggable: true,
    })
  }
}
