const avatars = [
  '/images/avatar/ic_avatar_1.png',
  '/images/avatar/ic_avatar_2.png',
  '/images/avatar/ic_avatar_3.png',
  '/images/avatar/ic_avatar_4.png',
  '/images/avatar/ic_avatar_5.png',
  '/images/avatar/ic_avatar_6.png',
  '/images/avatar/ic_avatar_7.png',
  '/images/avatar/ic_avatar_8.png',
  '/images/avatar/ic_avatar_9.png',
  '/images/avatar/ic_avatar_10.png',
  '/images/avatar/ic_avatar_11.png',
  '/images/avatar/ic_avatar_12.png',
  '/images/avatar/ic_avatar_13.png',
  '/images/avatar/ic_avatar_14.png',
  '/images/avatar/ic_avatar_15.png',
  '/images/avatar/ic_avatar_16.png',
]

export function getAvatar(profileImage) {
  if (profileImage) {
    return `/images/avatar/ic_avatar_${profileImage}.png`
  }
  return `/images/avatar/ic_avatar_1.png`
}

export function getRandomAvatar() {
  return avatars[Math.floor(Math.random() * avatars.length)]
}
