import { atom } from 'recoil'
import Cookies from 'js-cookie'
import { v4 } from 'uuid'

export const KEY_USER_NAME_COOKIE = 'key_user_name'
export const KEY_USER_EMAIL_COOKIE = 'key_user_email'
export const KEY_USER_PROFILE_IMAGE_COOKIE = 'key_user_profile_image'
export const KEY_USER_REFERRAL_CODE_COOKIE = 'key_user_referralCode'
export const KEY_USER_ACCESSTOKEN_COOKIE = 'key_user_accesstoken'
export const KEY_STAKING_NOTI_DATE_COOKIE = 'key_staking_notice_popup'

const userNameState = atom({
  key: KEY_USER_NAME_COOKIE + v4(),
  default: Cookies.get(KEY_USER_NAME_COOKIE) || undefined,
})

const userEmailState = atom({
  key: KEY_USER_EMAIL_COOKIE + v4(),
  default: Cookies.get(KEY_USER_EMAIL_COOKIE) || undefined,
})

const userProfileImageState = atom({
  key: KEY_USER_PROFILE_IMAGE_COOKIE + v4(),
  default: Cookies.get(KEY_USER_PROFILE_IMAGE_COOKIE) || undefined,
})

const userReferralCodeState = atom({
  key: KEY_USER_REFERRAL_CODE_COOKIE + v4(),
  default: Cookies.get(KEY_USER_REFERRAL_CODE_COOKIE) || undefined,
})

const userAccessTokenState = atom({
  key: KEY_USER_ACCESSTOKEN_COOKIE + v4(),
  default: Cookies.get(KEY_USER_ACCESSTOKEN_COOKIE) || undefined,
})

const stakingNoticePopupState = atom({
  key: KEY_STAKING_NOTI_DATE_COOKIE + v4(),
  default: Cookies.get(KEY_STAKING_NOTI_DATE_COOKIE) || undefined,
})

export {
  userNameState,
  userEmailState,
  userAccessTokenState,
  userProfileImageState,
  userReferralCodeState,
  stakingNoticePopupState,
}
