import Script from 'next/script'
import { RecoilRoot } from 'recoil'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { Noto_Sans_KR } from 'next/font/google'
import '../styles/globals.css'
import { ThemeProvider } from 'next-themes'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import UserContext from '../components/UserContext'
import { useEffect, useRef } from 'react'
import Meta from '../components/Meta'
import Footer from '../components/footer'
import Header from '../components/header/Header'
import * as gtag from '../lib/gtag'

import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const inter = Noto_Sans_KR({ weight: '700', subsets: ['latin'] })

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const { t } = useTranslation('common')

  const pid = router.asPath
  const scrollRef = useRef({
    scrollPos: 0,
  })

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  return (
    <>
      <Meta title={t('site_name')} desc={t('description')} keyword={t('keywords')} />
      <DefaultSeo
        title={t('site_name')}
        description={t('description')}
        canonical={'https://explorer.walkmining.com'}
        twitter={{
          handle: '@Walkmining',
          site: '@Walkmining',
          cardType: 'summary_large_image',
        }}
        languageAlternates={[
          {
            hrefLang: 'en-US',
            href: 'https://explorer.walkmining.com/en',
          },
          {
            hrefLang: 'ko-KR',
            href: 'https://explorer.walkmining.com/ko',
          },
          {
            hrefLang: 'ja-JP',
            href: 'https://explorer.walkmining.com/ja',
          },
          {
            hrefLang: 'x-default',
            href: 'https://explorer.walkmining.com/',
          },
        ]}
        openGraph={{
          title: t('site_name'),
          siteName: t('site_name'),
          description: t('description'),
          type: 'website',
          url: 'https://explorer.walkmining.com',
          images: [
            {
              url: 'https://ad-banner.walkmining.com/web/explorer_preview.png',
              alt: t('site_name'),
              width: 491,
              height: 243,
            },
          ],
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="nextjs-google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
        }}
      />
      <ThemeProvider enableSystem={true} attribute="class">
        <UserContext.Provider value={{ scrollRef: scrollRef }}>
          <ToastContainer
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <RecoilRoot>
            <main className={inter.className}>
              {!pid.includes('/login') && <Header />}
              <Component {...pageProps} />
              {!pid.includes('/login') && <Footer />}
            </main>
          </RecoilRoot>
        </UserContext.Provider>
      </ThemeProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
