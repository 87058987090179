import Head from 'next/head'

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="https://global-images.walkmining.com/assets/favicon/explorer/favicon_64x64.png"
        />
        <link
          rel="icon shortcut"
          href="https://global-images.walkmining.com/assets/favicon/explorer/favicon_48x48.png"
          type="image/x-icon"
        ></link>
        <link
          rel="icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/favicon_16x16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/favicon_32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/favicon_48x48.png"
          sizes="48x48"
        />
        <link
          rel="icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/favicon_64x64.png"
          sizes="64x64"
        />
        <link
          rel="apple-touch-icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/apple_touch_icon_57x57.png"
          sizes="57x57"
        />
        <link
          rel="apple-touch-icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/apple_touch_icon_60x60.png"
          sizes="60x60"
        />
        <link
          rel="apple-touch-icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/apple_touch_icon_72x72.png"
          sizes="72x72"
        />
        <link
          rel="apple-touch-icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/apple_touch_icon_76x76.png"
          sizes="76x76"
        />
        <link
          rel="apple-touch-icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/apple_touch_icon_114x114.png"
          sizes="114x114"
        />
        <link
          rel="apple-touch-icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/apple_touch_icon_120x120.png"
          sizes="120x120"
        />
        <link
          rel="apple-touch-icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/apple_touch_icon_144x144.png"
          sizes="144x144"
        />
        <link
          rel="apple-touch-icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/apple_touch_icon_152x152.png"
          sizes="152x152"
        />
        <link
          rel="apple-touch-icon"
          href="https://global-images.walkmining.com/assets/favicon/explorer/apple_touch_icon_180x180.png"
          sizes="180x180"
        />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  )
}

Meta.defaultProps = {
  title: 'WalkMining Explorer - A bridge between health and finance Converts your steps to rewards!',
  keyword: 'wkm, wkg, blockchain, cryptocurrency, NFT, wallet, M2E, Health, move-to-earn, walk',
  desc: 'A bridge between health and finance Converts your steps to rewards!',
}

export default Meta
