import { http, responseOk, responseError } from './axios-client'
import _ from 'lodash'

export async function fetchSearch(q, isSSR = false) {
  return await http(isSSR)
    .get(`explorer/search?q=${q}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchSearchUser(referralCode, isSSR = false) {
  return await http(isSSR)
    .get(`explorer/search/users/${referralCode}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchSearchMining(miningHash, isSSR = false) {
  return await http(isSSR)
    .get(`explorer/search/mining/${miningHash}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}
